<template>
	<div>
		<div class="Carousel">
			<el-carousel trigger="click" height="2.81rem" :pause-on-hover="false">
				<el-carousel-item v-for="item in info.carousel" :key="item">
					<img :src="proxy.$axios.imgUrl + item.images" class="Carousel-img" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="main">
			<div class="index-content-box">
				<div class="index-content-title alcenter">
					services
					<img src="../../assets/image/ic_titzshi.png" />
					<div class="title-border"></div>
				</div>
				<div class="index-content-list1">
					<div class="list1-item" v-for="(item,index) in info.services" :key="index" @click="jump(2,item.id)">
						<img :src="proxy.$axios.imgUrl + item.icon" />
						<div class="list1-item-content">{{item.title}}</div>
					</div>
				</div>
			</div>

			<div class="index-content-box">
				<div class="index-content-title alcenter">
					SAIFU Solutions
					<img src="../../assets/image/ic_titzshi.png" />
					<div class="title-border"></div>
				</div>
				<div class="index-content-list2">
					<div class="list2-item" v-for="(item,index) in info.solutions" :key="index"
						@click="jump(3,item.id)">
						<img :src="proxy.$axios.imgUrl + item.icon" class="imgbg" />
						<div class="title">{{item.title}}</div>
					</div>
				</div>
			</div>

			<div class="index-content-box">
				<div class="index-content-title alcenter">
					news
					<img src="../../assets/image/ic_titzshi.png" />
					<div class="title-border"></div>
				</div>
				<div class="index-content-list3">
					<div class="list3-item" v-for="(item,index) in info.news" :key="index"  @click="jump(4,item.id,'/newsdetail-m')">
						<div class="title">
							{{item.title}}
						</div>
						<div class="content">
							{{item.synopsis}}
						</div>
						<div class="timer">
							{{item.date_en}}
						</div>
					</div>
				</div>
				<div class="index-content-list3-more flex-center" @click="jump(4,4,'/newsList-m')">
					Learn more
					<img src="../../assets/image/ic_morejt.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'

	import EventBus from "../../assets/common/event-bus.js"

	onMounted(() => {
		EventBus.emit("mgheadr", 1);
		getInfo()
	})

	const {
		proxy
	} = getCurrentInstance()
	//轮播
	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('api/index/getHomeData')
		info.value = res.data
	}

	const router = useRouter()

	function jump(type, id, url = '/content-M') {
		router.push({
			path: url,
			query: {
				type,
				id
			}
		})
	}

	const contentList = ref([{
			title: 'CRO',
			subhead: 'One-stop service',
			titlel: 'Serve',
			isDynamic: false
		},
		{
			title: '10',
			subhead: 'Experience',
			titlel: 'Year',
			isDynamic: true,
			issub: true
		},
		{
			title: '1100',
			subhead: 'Drug',
			titlel: '+',
			isDynamic: true
		},
		{
			title: '4000',
			subhead: '+',
			titlel: 'Project experience',
			isDynamic: true
		},
		{
			title: '2800',
			subhead: 'Key projects',
			titlel: '+',
			isDynamic: true
		},
		{
			title: '840',
			subhead: 'Innovation special',
			titlel: '+',
			isDynamic: true
		}
	])
</script>

<style scoped>
	.Carousel {
		margin-top: 0.04rem;
	}

	.Carousel-img {
		width: 100%;
		margin: 0 auto;
		height: 2.81rem;
	}

	.index-bar {
		width: 100%;
		background-color: #F6F6F6;
		padding: 0.21rem 0 0.18rem;
		text-align: center;
	}



	.index-content-title {
		font-size: 0.34rem;
		margin-top: 0.58rem;
		margin-bottom: 0.33rem;
		line-height: 0.1rem;
		position: relative;
		padding-bottom: 0.34rem;
		font-weight: bold;
		text-transform: uppercase;
	}

	.index-content-title>img {
		width: 0.19rem;
		height: 0.24rem;
		margin-left: 0.24rem;
	}

	.index-content-title .title-border {
		width: 0.6rem;
		height: 0.04rem;
		background: #DBDBDB;
		position: absolute;
		bottom: 0;
		left: 0;
	}


	.index-content-list1 {
		display: grid;
		grid-template-columns: repeat(2, 49%);
		grid-template-rows: 5.32rem 5.32rem;
		grid-gap: 0.26rem;

	}

	.index-content-list1 .list1-item {
		font-weight: 400;
		font-size: 0.25rem;
		color: #000000;
		line-height: 0.36rem;
		border: 1px solid #eeeeee;
	}

	.index-content-list1 .list1-item>img {
		width: 100%;
		height: 4.12rem;
		display: block;
	}

	.index-content-list1 .list1-item .list1-item-content {
		padding: 0.25rem 0.29rem;
		box-sizing: border-box;
	}

	.index-content-list2 {
		display: grid;
		grid-template-columns: repeat(2, 49%);
		grid-template-rows: 2.1rem 4.38rem;
		grid-gap: 0.26rem;
		font-weight: 500;
		font-size: 0.26rem;
		color: #fff;
		line-height: 0.36rem;
		z-index: 9;
	}

	.index-content-list2 .list2-item {
		position: relative;
		background-color: rgba(5, 61, 113, .5);
		padding: 0.26rem 0.28rem;
		box-sizing: border-box;
	}

	.index-content-list2 .list2-item>img {
		width: 100%;
		height: 100%;
		position: absolute;
		display: inherit;
		z-index: -1;
		left: 0;
		top: 0;
	}

	.index-content-list3 .list3-item {
		border: 1px solid #EEEEEE;
		margin-top: 0.24rem;
		padding: 0.28rem;
		box-sizing: border-box;
	}

	.index-content-list3 .list3-item .title {
		font-weight: 500;
		font-size: 0.26rem;
		color: #000000;
		line-height: 0.26rem;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		white-space: nowrap;
	}

	.index-content-list3 .list3-item .content,
	.index-content-list3 .list3-item .timer {
		font-weight: 400;
		font-size: 0.2rem;
		color: #666666;
		line-height: 0.28rem;
		margin-top: 0.21rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.index-content-list3-more {
		font-weight: 400;
		font-size: 0.2rem;
		color: #666666;
		line-height: 0.54rem;
		text-transform: uppercase;
		margin-top: 0.2rem;
	}

	.index-content-list3-more>img {
		height: 0.32rem;
		width: auto;
		margin-left: 0.15rem;
	}
</style>